<template>
  <div id="page-stickprovs">
    <h5 class="m-4">{{ $t('SAMPLE.SAMPLES') }}</h5>

    <Confirm
      title="Bekräfta"
      message="Är du säker på att du vill radera stickprovet?"
      ref="deleteStickprovConfirm"
      type="success"
      @confirm="onDeleteStickprovConfirm"
    />
    
    <StickprovWizard
      class="scroll-to-wizard"
      v-if="creating"
      :stickprovs="stickprovs"
      @stickprovCreated="stickprovCreated"
    />

    <StickprovTable
      :items="stickprovs"
      @show_history_toggled="show_history_toggled"
      @createStickprovClicked="createStickprovClicked"
      @selectStickprovClicked="selectStickprovClicked"
      @deleteStickprovClicked="deleteStickprovClicked"
      @startEvent="startEvent"
    />
    <div class="scroll-to-container"></div>
    <b-card
      title=""
      class="mb-2 edit-stickprov-container"
      ref="edit-stickprov"
      id="edit-stickprov"
      hide-footer
      v-if="editing"
    >
      <div>
        <b-form class="mt-8" @submit="saveStickprov">
          <!--<b-form-group
            id="input-group-stickprov_id"
            label="ID"
            label-for="input-stickprov_id"
            >
            <b-form-input
                id="input-stickprov_id"
                v-model="form.stickprov_id"
                type="text"
                disabled
            ></b-form-input>
          </b-form-group>-->

          <b-row>
            <b-col lg="12">
              <StickprovMembersTable
                class="search-results"
                :stickprov="stickprov"
                :members="members"
                :currentCompanyId="currentCompanyId"
                @memberSelected="memberSelected"
                @confirm="confirm"
                @reject="reject"
              />
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import StickprovTable from '@/view/pages/ml/stickprov/StickprovTable.vue';
import StickprovWizard from '@/view/pages/ml/stickprov/StickprovWizard.vue';
import StickprovMembersTable from '@/view/pages/ml/stickprov/StickprovMembersTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'stickprovs',
  components: {
    StickprovTable,
    StickprovWizard,
    StickprovMembersTable,
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      toDelete: null,
      stickprov: null,
      members: [],
      showHistory: false,
      form: {
        stickprov_id: '',
        is_stopped: 0,
        is_finished: 0,
        num_sent_rounds: 0,
        num_target_rounds: 0,
        deadline: '2020-01-01',
        company_id: '',
        sms_template_id: '',
        email_template_id: '',
        call_only: 0,
        num_answers: 0,
        num_sms_sent: 0,
        num_email_sent: 0,
        pct_members: 0,
        num_members: 0,
        period_id: '',
        num_reject: 0,
        send_email: 0,
        send_sms: 0,
        executing: 0,
        next_execution: '2020-01-01'
      },
      online: false,
      creating: false,
      editing: false,
      stickprovs: [],
      templates: [],
      emailTemplatesOptions: [],
      smsTemplatesOptions: []
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadStickprovs();
      }
    },
    stickprovs(newValue, oldValue) {
      if (newValue) {
      }
    }
  },
  methods: {
    confirm(member_id) {
      axios
        .put('/member_stickprov', {
          member_id: member_id,
          answer: 'confirm',
          stickprov_id: this.form.stickprov_id
        })
        .then(res => {
          for (var i = 0; i < this.members.length; ++i) {
            if (this.members[i].member_id === member_id) {
              this.members[i].st_conf = 1;
            }
          }
        })
        .catch(err => {
          console.error(err);

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_CONFIRM'));
        });
    },
    reject(member_id) {
      axios
        .put('/member_stickprov', {
          member_id: member_id,
          answer: 'reject',
          stickprov_id: this.form.stickprov_id
        })
        .then(res => {
          for (var i = 0; i < this.members.length; ++i) {
            if (this.members[i].member_id === member_id) {
              this.members[i].st_rej = 1;
            }
          }
        })
        .catch(err => {
          console.error(err);

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_REJECT'));
        });
    },
    loadMembers(stickprov_id) {
      axios
        .get(`/member_stickprov/${stickprov_id}`)
        .then(res => {
          this.members = res.data;

        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_LIST_MEMBERS'));
        });
    },
    memberSelected(member) {

    },
    show_history_toggled(show) {
      this.showHistory = show;

      this.loadStickprovs();
    },
    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }

      this.loadTemplates();
      this.loadStickprovs();
    },
    createStickprovClicked() {
      this.createStickprov();
    },
    createStickprov() {
      this.creating = true;
      // this.editing = true;

      //this.scrollToEditStickprov();
      this.scrollToWizard();
    },
    deleteStickprovClicked(stickprov_id) {
      this.toDelete = stickprov_id;
      this.$refs['deleteStickprovConfirm'].show();
    },
    onDeleteStickprovConfirm() {
      this.deleteStickprov(this.toDelete);
      this.toDelete = null;
    },
    deleteStickprov(stickprov_id) {
      axios
        .delete(`/stickprov/${stickprov_id}`)
        .then(res => {
          if (res.status === 204) {
            this.stickprovs = this.stickprovs.filter(item => item.stickprov_id !== stickprov_id);

            this.creating = false;
            this.editing = false;

            this.toastr('success', this.$t('COMMON.OK'), this.$t('SAMPLE.SAMPLE_DELETED') );
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_DELETE') );
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_DELETE'));
        });
    },
    selectStickprovClicked(stickprov_id) {
      axios
        .get(`/stickprov/${stickprov_id}`)
        .then(res => {
          this.form = res.data;
          this.creating = false;
          this.editing = true;
          this.stickprov = this.form;

          this.loadMembers(this.form.stickprov_id);

          this.scrollToEditStickprov();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_GET') );
        });
    },
    startEvent(stickprov_id, status) {
      if (status)
        axios
          .post(`/stickprov/start/${stickprov_id}`, { })
          .then(res => {
            if (res.status === 200) {
              let index = this.stickprovs.findIndex(item => item.stickprov_id === stickprov_id);
              if (index > -1) {
                this.stickprovs[index] = res.data;
                // Vue3 compatability for Vue.set
                this.stickprovs = [...this.stickprovs];
                this.toastr('success', this.$t('COMMON.OK'), this.$t('SAMPLE.STARTED') );
              }
            } else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_START') );
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_START') );
          });
      else
        axios
          .post(`/stickprov/stop/${stickprov_id}`, { })
          .then(res => {
            if (res.status === 200) {
              let index = this.stickprovs.findIndex(item => item.stickprov_id === stickprov_id);
              if (index > -1) {
                this.stickprovs[index] = res.data;
                // Vue3 compatability for Vue.set
                this.stickprovs = [...this.stickprovs];
                this.toastr('success', this.$t('COMMON.OK'), this.$t('SAMPLE.STOPPED') );
              }
            } else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_STOP') );
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte stoppa stickprov');
          });
    },
    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },
    loadStickprovs() {
      this.stickprovs = [];
      axios
        //.get(`/company/liststickprovs?company_id=${this.currentCompanyId}`)
        .get(`/stickprov/creatorcompany/${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            var stickprovs = res.data;

            var now = dayjs();

            for (var i = 0; i < stickprovs.length; ++i) {
              var stickprov = stickprovs[i];

              var stickprovdt = dayjs(stickprov.deadline);

              if ((stickprovdt < now && this.showHistory) || stickprovdt >= now) {
                //check stickprov date
                this.stickprovs.push(stickprov);
              }
            }
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_LIST') );
        });
    },
    stickprovCreated(createdStickprover) {
      //this.loadStickprovs();

      //this.stickprovs = [];

      for (const s of createdStickprover) {
        this.stickprovs.push(s);
      }

      this.creating = false;

      // this.loadMembers(stickprov.stickprov_id);
    },
    loadTemplates() {
      this.templates = [];
      this.smsTemplatesOptions = [];
      this.emailTemplatesOptions = [];

      axios
        .get(`/template?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            this.templates = res.data;
          }

          for (var i = 0; i < this.templates.length; ++i) {
            /*if (this.templates[i].type === 'email') {
              this.emailTemplatesOptions.push(this.templates[i]);
            }
            else {
              this.smsTemplatesOptions.push(this.templates[i]);
            }*/
            if (this.templates[i].is_sms === 0) {
              this.emailTemplatesOptions.push({
                value: this.templates[i].template_id,
                text: this.templates[i].name
              });
            } else {
              this.smsTemplatesOptions.push({
                value: this.templates[i].template_id,
                text: this.templates[i].name
              });
            }
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
        });
    },
    scrollToEditStickprov() {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
    scrollToWizard() {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName('scroll-to-wizard')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
    saveStickprov(e) {
      if (e !== undefined) {
        e.prstickprovDefault();
      }

      this.form.company_id = this.currentCompanyId;

      this.form.type = this.online ? 'ONL' : 'PHY';

      axios
        .put(`/stickprov/${this.form.stickprov_id}`, this.form)
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('SAMPLE.UPDATED') );
            this.loadStickprovs();
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SAMPLE.UNABLE_UPDATE'));
        });
    },

  }
};
</script>
